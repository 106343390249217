import styled from "styled-components"

export const SliderArrow = styled.button`
  position: absolute;
  z-index: 50;
  top: calc(50% - 15px);
  width: 35px;
  height: 35px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  transform: rotateZ(-45deg);
  outline: none;
  color: white;
  transition: 0.2s;
  &:hover {
    color: greenyellow;
  }
`
